@import url("https://fonts.googleapis.com/css?family=Exo");
html {
  font-size: 14px; }

body,
html {
  height: 100%;
  font-family: 'Exo', sans-serif; }

body {
  background: #ffffff;
  font-family: 'Exo', sans-serif;
  color: #333333; }

.wrapper {
  display: flex;
  width: 100%; }

#content {
  width: calc(100% - 250px);
  min-height: 100vh;
  transition: all 0.6s cubic-bezier(0.945, 0.02, 0.27, 0.665);
  position: absolute;
  top: 0;
  right: 0; }
  #content.active {
    width: 100%; }

.bg-menu {
  background-color: #0071ff; }

#navbar .img-navbar {
  padding: 0; }
  #navbar .img-navbar img {
    height: 40px; }
    @media (max-width: 599px) {
      #navbar .img-navbar img {
        height: 25px; } }
  #navbar .img-navbar:after {
    display: none; }

#navbar .dropdown-menu {
  font-size: 0.875rem; }

#navbar .badge {
  position: absolute;
  top: 5px;
  right: 0; }

#navbar .nav-link {
  display: block;
  padding: 0.5rem 1rem;
  line-height: 40px; }

#homepage {
  /*
    * ==========================================================
    *      DASHBOARD COUNTS
    * ==========================================================
    */
  background-color: #f4f7fa;
  /* DASHBOARD COUNTS MEDIAQUERIES ------------------------- */
  /*
    * ==========================================================
    *     DASHBOARD HEADER
    * ==========================================================
    */
  /* DASHBOARD HEADER MEDIAQUERIES ------------------------- */
  /*
    * ==========================================================
    *      STATISTICS
    * ==========================================================
    */
  /* STATISTICS  MEDIAQUERIES ------------------------- */
  /*
    * ==========================================================
    *      UPDATES WIDGETS
    * ==========================================================
    */
  /* UPDATES MEDIAQUERIES ------------------------- */
  /*
    * ==========================================================
    *      DAILY FEEDS AND ACTIVITES WIDGETS
    * ==========================================================
    */
  /*  Daily Feeds ------------------- */
  /* Activities  ------------------- */
  /* UPDATES WIDGET MEDIAQUERIES ------------------------- */
  /*
    * ==========================================================
    *      PROGRESS CIRCLE
    * ==========================================================
    */
  /*
    * 9. CARD
    */ }
  #homepage .dashboard-counts {
    color: #333333; }
  #homepage .dashboard-counts .count-title span {
    font-size: 0.8em;
    color: #aaaaaa;
    display: block; }
  #homepage .dashboard-counts .count-title i {
    font-size: 1.5em;
    color: #2b90d9; }
  #homepage .dashboard-counts .icon {
    margin-right: 10px; }
  #homepage .dashboard-counts strong {
    font-size: 0.9em;
    font-weight: 500;
    color: #555555;
    display: block; }
  #homepage .dashboard-counts .count-number {
    font-size: 2em;
    font-weight: 300; }
  @media (max-width: 991px) {
    #homepage .dashboard-counts div[class*='col-'] {
      margin-bottom: 20px; } }
  @media (min-width: 768px) {
    #homepage .dashboard-counts strong {
      font-size: 1em; }
    #homepage .dashboard-counts span {
      font-size: 0.9em; }
    #homepage .dashboard-counts .count-number {
      font-size: 2em; } }
  #homepage .dashboard-header {
    background: #ffffff;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee; }
  #homepage .dashboard-header .check-lists {
    margin-top: 20px; }
  #homepage .dashboard-header .check-lists label {
    font-size: 0.75em; }
  #homepage .dashboard-header .pie-chart {
    position: relative;
    width: 300px;
    height: 300px;
    max-width: 100%;
    margin: 0 auto; }
  #homepage .dashboard-header p {
    font-size: 0.75rem;
    font-weight: 400;
    color: #888888; }
  #homepage .dashboard-header li {
    margin-bottom: 10px; }
  @media (max-width: 991px) {
    #homepage .dashboard-header {
      background: none;
      border: none;
      padding: 0; }
    #homepage .dashboard-header div[class*='col-'] {
      margin-bottom: 30px; }
    #homepage .dashboard-header div[class*='col-']:last-of-type {
      margin-bottom: 0; }
    #homepage .dashboard-header .card {
      background: #ffffff;
      padding: 30px;
      -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
      height: 100%; } }
  @media (min-width: 768px) {
    #homepage .dashboard-header p {
      font-size: 0.9em; }
    #homepage .dashboard-header h2 {
      font-size: 1.3rem; }
    #homepage .dashboard-header .check-lists label {
      font-size: 0.85em; } }
  @media (min-width: 992px) {
    #homepage .dashboard-header {
      margin-bottom: 30px; }
    #homepage .dashboard-header .card {
      -webkit-box-shadow: none;
      box-shadow: none;
      margin-bottom: 0; } }
  #homepage .statistics {
    /* User Activity ------------- */ }
  #homepage .statistics div[class*='col-'] .card {
    padding: 20px;
    margin-bottom: 0;
    height: 100%; }
  #homepage .statistics h2 {
    margin-bottom: 20px; }
  #homepage .statistics p {
    font-size: 0.8em;
    color: #999999;
    margin-bottom: 5px; }
  #homepage .statistics .income .icon {
    font-size: 3.5em; }
  #homepage .statistics .income .number {
    font-size: 2.5em;
    color: #555555; }
  #homepage .statistics .income strong {
    font-size: 1.5em; }
  #homepage .statistics .data-usage canvas {
    width: 100%;
    max-width: 200px;
    border: none; }
  #homepage .statistics .data-usage strong {
    font-size: 1.5em;
    display: block; }
  #homepage .statistics .data-usage small {
    font-size: 0.85em;
    font-weight: 300;
    color: #aaaaaa; }
  #homepage .statistics .data-usage span {
    font-size: 0.95em;
    color: #aaaaaa;
    display: block; }
  #homepage .statistics .data-usage p {
    margin-top: 10px;
    margin-bottom: 0; }
  #homepage .statistics .user-activity .number {
    font-size: 2.5em;
    font-weight: 300;
    color: #555555; }
  #homepage .statistics .user-activity .progress {
    border-radius: 0;
    background: #f5f5f5; }
  #homepage .statistics .user-activity .progress .progress-bar.bg-primary {
    background: #2b90d9 !important; }
  #homepage .statistics .user-activity .page-statistics {
    margin-top: 20px; }
  #homepage .statistics .user-activity .page-statistics strong {
    display: block;
    text-transform: uppercase;
    color: #2b90d9;
    font-size: 2em; }
  @media (max-width: 991px) {
    #homepage .statistics div[class*='col-'] .card {
      height: auto;
      margin-bottom: 30px; }
    #homepage .statistics div[class*='col-']:last-of-type .card {
      margin-bottom: 0; }
    #homepage .statistics .data-usage {
      text-align: center; }
    #homepage .statistics .data-usage div[class*='col-']:first-of-type {
      padding-bottom: 20px; }
    #homepage .statistics .data-usage .gmpc {
      padding-bottom: 20px !important; }
    #homepage p {
      font-size: 0.75em; } }
  @media (min-width: 768px) {
    #homepage .statistics h2 {
      font-size: 1.3rem; } }
  #homepage .updates .left-col {
    padding-right: 20px; }
  #homepage .updates .icon {
    margin-right: 10px; }
  #homepage .updates li {
    border-bottom: 1px solid #f3f3f3;
    padding: .75rem 1.25rem; }
  #homepage .updates .update-date {
    font-size: 1.3em;
    font-weight: 700;
    color: #bbbbbb;
    display: block; }
  #homepage .updates span {
    font-size: 0.7em;
    font-weight: 300; }
  #homepage .updates strong {
    font-weight: 400;
    color: #555555;
    font-size: 0.9em;
    margin-left: 10px; }
  #homepage .updates small {
    font-size: 0.75em; }
  #homepage .updates a {
    color: #555555; }
  #homepage .updates a:hover, #homepage .updates a:focus {
    color: #555555;
    text-decoration: none; }
  #homepage .updates h2 {
    margin-bottom: 0; }
  #homepage .updates p {
    font-size: 0.9em;
    color: #999999;
    margin-bottom: 5px; }
  @media (max-width: 991px) {
    #homepage .updates h2 {
      font-size: 1.05rem; }
    #homepage .updates strong {
      font-size: 1em;
      font-weight: 400; }
    #homepage .updates small {
      font-size: 0.85em; }
    #homepage .updates .full-date {
      font-size: 0.85em; } }
  #homepage .daily-feeds a.feed-profile {
    margin-right: 10px;
    width: 45px;
    height: 45px; }
  #homepage .daily-feeds a.feed-profile img {
    width: 100%;
    height: 100%; }
  #homepage .daily-feeds .badge {
    margin-right: 10px; }
  #homepage .daily-feeds strong {
    display: inline-block;
    margin-right: 5px; }
  #homepage .daily-feeds small {
    color: #999999;
    display: block;
    margin-bottom: 5px; }
  #homepage .daily-feeds .message-card {
    padding: 10px;
    background: #fafafa;
    margin-left: 55px;
    margin-top: 10px;
    margin-bottom: 10px;
    -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); }
  #homepage .daily-feeds .full-date {
    line-height: 0.7em;
    margin-bottom: 10px; }
  #homepage .daily-feeds .CTAs a {
    margin-right: 5px; }
  #homepage .daily-feeds .CTAs a i {
    margin-right: 5px; }
  #homepage .daily-feeds .CTAs a:last-of-type {
    margin-right: 0; }
  #homepage .daily-feeds a.btn {
    color: #ffffff; }
  #homepage .activities li {
    padding: 0; }
  #homepage .activities .date-holder {
    padding-right: 0; }
  #homepage .activities .date-holder .date {
    -webkit-box-shadow: none;
    box-shadow: none; }
  #homepage .activities .date-holder .date span {
    font-size: 0.8em; }
  #homepage .activities .date-holder .icon {
    width: 30px;
    height: 30px;
    line-height: 30px;
    background: #f5f5f5;
    text-align: center;
    margin: 0;
    display: inline-block; }
  #homepage .activities .date-holder span {
    display: block;
    margin: 10px; }
  #homepage .activities .content {
    border-left: 1px solid #eeeeee;
    border-bottom: 20px;
    padding: 10px 20px; }
  #homepage .activities .content strong {
    display: block;
    margin-right: 5px; }
  @media (min-width: 768px) {
    #homepage .updates strong {
      font-size: 1em; }
    #homepage .updates small {
      font-size: 0.8em; } }
  #homepage .gmpc {
    padding: 0 !important;
    width: 100% !important;
    height: auto !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    color: #2b90d9 !important;
    border-right: 1px solid #eeeeee; }
  @media (max-width: 575px) {
    #homepage .gmpc {
      border-right: none;
      border-bottom: 1px solid #eeeeee; } }
  #homepage .section-padding {
    padding: 1rem 0; }
  #homepage .section-no-padding-bottom {
    padding-bottom: 0; }
  #homepage section header {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  #homepage button {
    cursor: pointer; }
  #homepage .card {
    background-color: #ffffff;
    border: 0 solid #eeeeee;
    border-radius: 0; }
  #homepage .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  #homepage .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  #homepage .card-body {
    padding: 1.25rem; }
  #homepage .card-title {
    margin-bottom: 1rem; }
  #homepage .card-subtitle {
    margin-top: -0.5rem; }
  #homepage .card-link + .card-link {
    margin-left: 1.25rem; }
  #homepage .card-header {
    padding: 1rem 1.25rem;
    background-color: #ffffff;
    border-bottom: 1px solid #eeeeee; }
  #homepage .card-header:first-child {
    border-radius: 0 0 0 0; }
  #homepage .card-header-transparent {
    background-color: rgba(0, 0, 0, 0.3);
    border-bottom: none; }
  #homepage .card-footer {
    padding: 1rem 1.25rem;
    background-color: #f8f9fa;
    border-top: 1px solid #eeeeee; }
  #homepage .card-footer:last-child {
    border-radius: 0 0 0 0; }
  #homepage .card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -1rem;
    margin-left: -0.625rem;
    border-bottom: 0; }
  #homepage .card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem; }
  #homepage .card-img-overlay {
    padding: 1.25rem; }
  #homepage .card-img-overlay-opacity {
    background: rgba(0, 0, 0, 0.2); }
  #homepage .card-img {
    border-radius: 0; }
  #homepage .card-img-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  #homepage .card-img-bottom {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  #homepage .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    #homepage .card-deck {
      margin-right: -15px;
      margin-left: -15px; }
    #homepage .card-deck .card {
      margin-right: 15px;
      margin-left: 15px; } }

.bg-menu {
  background-color: #0071ff; }

#sidebar {
  /* don't forget to add all the previously mentioned styles here too */
  background: #0071ff;
  color: #ffffff;
  transition: all 0.6s cubic-bezier(0.945, 0.02, 0.27, 0.665);
  transform-origin: bottom left;
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 999; }
  #sidebar .language-toggle:after {
    content: none; }
  #sidebar.active {
    margin-left: -250px;
    transform: rotateY(100deg);
    /* Rotate sidebar vertically by 100 degrees. */ }
  #sidebar .sidebar-text {
    display: flex;
    align-items: center; }
  #sidebar .logo-sidebar {
    width: 50px;
    height: 50px;
    background-size: 50px;
    border-radius: 50%;
    background-image: url(/content/images/eDocuments_logo_02.png);
    background-repeat: no-repeat;
    display: inline-block; }
  #sidebar a[data-toggle="collapse"] {
    position: relative; }
  #sidebar .dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%); }
  #sidebar p {
    font-size: 1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #ffffff; }
  #sidebar a, #sidebar a:hover, #sidebar a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s; }
  #sidebar .sidebar-header {
    padding: 20px;
    background: #0071ff; }
  #sidebar ul.components {
    border-bottom: 1px solid #0071ff;
    padding: 0 0 20px 0; }
  #sidebar ul p {
    color: #ffffff;
    padding: 10px; }
  #sidebar ul li a {
    padding: 10px;
    font-size: 1em;
    display: block; }
  #sidebar ul li a:hover {
    color: #338dff;
    background: #ffffff; }
  #sidebar ul li.active > a, #sidebar a[aria-expanded="true"] {
    color: #ffffff;
    background: #66aaff; }
  #sidebar ul ul a {
    font-size: 0.875em !important;
    padding-left: 30px !important;
    background: #338dff; }
  #sidebar .sidebar-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 250px;
    align-items: center;
    background-color: #0071ff; }
    #sidebar .sidebar-footer .dropdown-item.active, #sidebar .sidebar-footer .dropdown-item:active {
      background-color: #0071ff;
      color: #ffffff; }
    #sidebar .sidebar-footer .dropdown-menu {
      background-color: transparent; }
    #sidebar .sidebar-footer .logo {
      height: 25px;
      margin-right: 20px;
      float: right; }
    #sidebar .sidebar-footer .version {
      font-size: 10px;
      margin-left: 10px; }

.form-document-external-access {
  width: 100%;
  max-width: 420px;
  padding: 15px;
  margin: auto; }

.form-documents-external-access {
  width: 100%;
  max-width: 1024px;
  padding: 15px;
  margin: auto; }

.external-logo-text {
  display: inline-flex;
  align-items: center;
  color: #0071ff;
  font-weight: bolder; }

.external-logo {
  width: 50px;
  height: 50px;
  background-size: 50px;
  border-radius: 50%;
  background-image: url(/content/images/eDocuments_logo_02.png);
  background-repeat: no-repeat;
  display: inline-block; }

/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important; }

/* ==========================================================================
Development Ribbon
========================================================================== */
.ribbon {
  background-color: #aa0000;
  box-shadow: 0 0 10px #888888;
  left: -3.5em;
  moz-box-shadow: 0 0 10px #888888;
  moz-transform: rotate(-45deg);
  ms-transform: rotate(-45deg);
  o-transform: rotate(-45deg);
  overflow: hidden;
  position: absolute;
  top: 40px;
  transform: rotate(-45deg);
  webkit-box-shadow: 0 0 10px #888888;
  webkit-transform: rotate(-45deg);
  white-space: nowrap;
  width: 15em;
  z-index: 9999;
  pointer-events: none; }
  .ribbon a {
    border: 1px solid #ffaaaa;
    color: #ffffff;
    display: block;
    font: bold 81.25% 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 1px 0;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444444;
    pointer-events: none; }

.readonly {
  background-color: #eeeeee;
  opacity: 1; }

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a:hover,
.hand,
[jh-sort-by] {
  cursor: pointer; }

/* ==========================================================================
Metrics and Health styles
========================================================================== */
#threadDump .popover,
#healthCheck .popover {
  top: inherit;
  display: block;
  font-size: 10px;
  max-width: 1024px; }

#healthCheck .popover {
  margin-left: -50px; }

.health-details {
  min-width: 400px; }

/* ==========================================================================
entity tables helpers
========================================================================== */
/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
/* Makes toolbar not wrap on smaller screens
   http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-end;
  justify-content: flex-end; }

.jh-table > tbody > tr > td {
  /* Align text in td vertically (top aligned by Bootstrap) */
  vertical-align: middle; }

.jh-table > thead > tr > th > .fa-sort,
.jh-table > thead > tr > th > .fa-sort-amount-up,
.jh-table > thead > tr > th > .fa-sort-amount-up-down {
  /* less visible sorting icons */
  opacity: 0.5; }
  .jh-table > thead > tr > th > .fa-sort:hover,
  .jh-table > thead > tr > th > .fa-sort-amount-up:hover,
  .jh-table > thead > tr > th > .fa-sort-amount-up-down:hover {
    /* full visible sorting icons and pointer when mouse is over them */
    opacity: 1;
    cursor: pointer; }

/* ==========================================================================
entity detail page css
========================================================================== */
.dl-horizontal.jh-entity-details > dd {
  margin-bottom: 15px; }

@media screen and (min-width: 768px) {
  .dl-horizontal.jh-entity-details > dt {
    margin-bottom: 15px; }
  .dl-horizontal.jh-entity-details > dd {
    border-bottom: 1px solid #eeeeee;
    padding-left: 180px;
    margin-left: 0; } }

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav,
.pagination,
.carousel,
.panel-title a {
  cursor: pointer; }

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup > li > div.uib-datepicker > table .btn-default {
  border: 0; }

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup > li > div.uib-datepicker > table:focus {
  outline: none; }

/* jhipster-needle-scss-add-main JHipster will add new css style */
.no-margin {
  margin: 0; }

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto; }

.login-logo-text {
  display: inline-flex;
  align-items: center;
  color: #0071ff;
  font-weight: bolder;
  font-size: xx-large; }

.login-logo {
  width: 75px;
  height: 75px;
  background-size: 75px;
  border-radius: 50%;
  background-image: url(/content/images/eDocuments_logo_02.png);
  background-repeat: no-repeat;
  display: inline-block; }

pre {
  font-size: 12px; }

ul[dnd-list] {
  min-height: 49px;
  padding-left: 0; }

ul[dnd-list] .dndPlaceholder {
  background-color: #0071ff;
  display: block;
  min-height: 49px; }

ul[dnd-list] .dndDraggingSource {
  display: none; }

.img-perfil {
  max-height: 50px; }

.hidden {
  display: none; }

p.watermark {
  position: absolute;
  color: #0071ff;
  font-size: 100px;
  pointer-events: none;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  opacity: 0.1;
  top: 3em;
  text-align: center;
  width: 100%;
  vertical-align: middle; }

/* [START] FOOTER --------------------------------- */
.footer_wrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  border: 0; }

.footer_wrapper span {
  display: block;
  text-align: right; }

.footer_logo {
  height: 25px;
  margin-right: 20px; }

.footer_wrapper span a:hover {
  background-position: 10px -40px;
  -webkit-transition: 0.5s ease;
  -moz-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  transition: 0.5s ease; }

/* [END] FOOTER ---------------------------------*/
/* UI Select ---------------------------------*/
.ui-select-choices.ui-select-choices-content.ui-select-dropdown.dropdown-menu {
  display: block; }

.bggradient {
  background: linear-gradient(180deg, #09043c 0%, rgba(41, 0, 119, 0.99) 75%), #290077; }
